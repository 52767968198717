import CryptoJS from 'crypto-js'

export const KEY = process.env.SIGNATURE_IMAGE_KEY || ''
export const SALT = process.env.SIGNATURE_IMAGE_SALT || ''

const hexDecode = (hex) => CryptoJS.enc.Hex.parse(hex)

interface ImageSignatureResult {
  signature: string
}

const secretHex = hexDecode(KEY)
const saltHex = hexDecode(SALT)

export const sign = (path: string): ImageSignatureResult => {
  const hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secretHex)

  hmac.update(saltHex)
  hmac.update(path)

  const hash = hmac.finalize().toString(CryptoJS.enc.Base64url)

  return {
    signature: hash
  }
}
