import React from 'react'

import MuiSkeleton, {
  SkeletonProps as MuiSkeletonProps
} from '@mui/material/Skeleton'

export type SkeletonProps = MuiSkeletonProps & {
  children?: React.ReactNode
  loading?: boolean
}

const Skeleton = ({ children, loading = true, ...props }: SkeletonProps) => {
  return loading ? <MuiSkeleton {...props} /> : <>{children}</>
}

export default Skeleton
