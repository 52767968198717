import React from 'react'

import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabel
} from '@mui/material'

export type CheckboxProps = MuiCheckboxProps & {
  label?: string
}

const Checkbox = ({ label, color = 'secondary', ...props }: CheckboxProps) => {
  if (label) {
    return (
      <FormControlLabel
        componentsProps={{ typography: { onClick: props.onClick } }}
        label={label}
        sx={{
          '& .MuiTypography-root': {
            fontSize: '14px'
          },

          '& .MuiCheckbox-root': {
            paddingRight: 1
          }
        }}
        control={<MuiCheckbox color={color} {...props} size="small" />}
      />
    )
  }

  return <MuiCheckbox color={color} {...props} />
}

export default Checkbox
