import { sign } from './imageSignature'

const imageLoader = ({ src, width, quality = 75 }) => {
  const uri = process.env.IMGPROXY_ORIGIN_HOST || ''
  const path = `/q:${quality}/size:${width}/resizing_type:fill/${src}`

  const { signature } = sign(path)

  return `${uri}/${signature}${path}`
}

export default imageLoader
