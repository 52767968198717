import React, { useCallback } from 'react'

import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MuiMenuItem, {
  MenuItemProps as MuiMenuItemProps
} from '@mui/material/MenuItem'

import Checkbox from 'app/components/base/Checkbox'

export type OptionMenuItemProps = MuiMenuItemProps & {
  variant?: 'default' | 'checkbox'
  selected?: boolean
  children: React.ReactNode
  start?: React.ReactNode
  end?: React.ReactNode
}

const OptionMenuItem = ({
  variant = 'default',
  selected,
  children,
  start,
  end,
  disabled,
  sx,
  ...props
}: OptionMenuItemProps) => {
  const hasVariant = useCallback(
    (v: OptionMenuItemProps['variant']) => v === variant,
    [variant]
  )

  return (
    <MuiMenuItem
      dense
      selected={selected}
      autoFocus={false}
      disabled={disabled}
      color="#E5F9F6"
      sx={sx}
      tabIndex={-1}
      {...props}
    >
      {hasVariant('checkbox') && (
        <ListItemIcon sx={{ ml: -1, pointerEvents: 'none' }}>
          <Checkbox checked={selected} disabled={disabled} />
        </ListItemIcon>
      )}
      {!!start && start}
      <ListItemText>{children}</ListItemText>
      {!!start && end}
    </MuiMenuItem>
  )
}

export default OptionMenuItem
