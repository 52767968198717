import { useCallback, useMemo } from 'react'

import { QueryOptions, useQuery } from 'react-query'

import { isOfType } from 'app/utils/helpers/type.helpers'

export type OptionModel = {
  id?: string
  value: any
  label: any
  description?: string
  group?: string
  firstInGroup?: boolean
  source?: string
}

export const formatOption = ({
  option,
  optionValue,
  optionLabel,
  optionDescription
}: {
  option: any
  optionValue: string
  optionLabel: string
  optionDescription: string
}): OptionModel => {
  if (
    isOfType.object(option) &&
    optionValue in option &&
    optionLabel in option
  ) {
    const {
      [optionValue]: value,
      [optionLabel]: label,
      [optionDescription]: description,
      ...rest
    } = option
    return { value, label, description, ...rest }
  }

  if (isOfType.object(option)) {
    return option as OptionModel
  }

  return { value: option, label: option }
}

const getOptionByValue = ({ value, optionValue, caseSensitive, options }) => {
  const formatValue = (v) => {
    if (caseSensitive) {
      return String(v)
    }

    return String(v).toLowerCase()
  }

  return options.find(
    (option) =>
      formatValue(option.value) === formatValue(value?.[optionValue] || value)
  )
}

const getFormattedOptions = ({
  data,
  formatOptions,
  optionLabel,
  optionValue,
  optionDescription
}) => {
  let formattedOps = data.map((option) =>
    formatOption({
      option,
      optionLabel,
      optionValue,
      optionDescription
    })
  )

  if (typeof formatOptions === 'function') {
    formattedOps = formatOptions(formattedOps)
  }

  return formattedOps
}

export type UseOptionsProps = {
  key: any
  options?: any[]
  value?: any
  enabled?: boolean
  multiple?: boolean
  optionLabel?: string
  optionValue?: string
  optionDescription?: string
  caseSensitive?: boolean
  queryOptions?: QueryOptions
  unselectable?: boolean
  loadOptions?: () => Promise<any>
  formatOptions?: (resp: any) => Promise<any> | any
  onChange?: (value: any) => void
}

const useOptions = ({
  options: customOptions = [],
  loadOptions,
  formatOptions,
  optionLabel = 'name',
  optionValue = 'id',
  optionDescription,
  value,
  key = null,
  caseSensitive,
  enabled,
  queryOptions,
  multiple,
  unselectable,
  onChange = () => {}
}: UseOptionsProps) => {
  const {
    data: options,
    isLoading,
    refetch
  } = useQuery<any, any, any[]>(['options', key], loadOptions || (() => []), {
    enabled: !!loadOptions && enabled,
    select: (data: any[]) => {
      return getFormattedOptions({
        data,
        formatOptions,
        optionLabel,
        optionValue,
        optionDescription
      })
    },
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    ...queryOptions
  })

  const optionsIsLoad = options?.length

  const newOptions = useMemo(() => {
    if (isOfType.array(customOptions) && !optionsIsLoad) {
      return getFormattedOptions({
        data: customOptions,
        formatOptions,
        optionLabel,
        optionValue,
        optionDescription
      })
    }

    return options || []
  }, [customOptions, optionLabel, optionValue, optionsIsLoad])

  const getOptionByValueMemo = useCallback(
    (value) => {
      const defaultParams = {
        caseSensitive,
        options: newOptions,
        optionValue
      }

      return getOptionByValue({ ...defaultParams, value })
    },
    [caseSensitive, newOptions, optionValue]
  )

  const valueOption = useMemo(() => {
    if (multiple ? !value?.length : !value) return multiple ? [] : null

    const primitive = isOfType.string(value) || isOfType.number(value)
    const isOptions = !!newOptions.length

    if (primitive && isOptions) {
      return getOptionByValueMemo(value)
    }
    if (isOfType.object(value) && value?.[optionValue] && isOptions) {
      return getOptionByValueMemo(value)
    }
    if (isOfType.array(value) && isOptions) {
      return value
        .map((currValue) => getOptionByValueMemo(currValue))
        .filter(Boolean)
    }

    return value
  }, [value, optionValue, newOptions])

  const onChangeOption = useCallback(
    (value) => {
      if (isOfType.array(value)) {
        onChange(value)
      } else if (isOfType.array(valueOption)) {
        const oldLength = valueOption?.length
        const filteredValue = valueOption
          ?.filter((v) => v.value !== value)
          .map(({ value }) => value)
        const newLength = filteredValue?.length

        if (oldLength === newLength) {
          onChange([...filteredValue, value])
        } else {
          onChange([...filteredValue])
        }
      } else {
        if (unselectable) {
          const isEqual = value === valueOption?.value
          const equalValue = typeof valueOption?.value === 'string' ? '' : null
          onChange(isEqual ? equalValue : value)
        } else {
          onChange(value)
        }
      }
    },
    [onChange, valueOption, unselectable]
  )

  return {
    loading: isLoading,
    options: newOptions as OptionModel[],
    valueOption,
    onChangeOption,
    refetch
  }
}

export default useOptions
