import React, { ReactElement, ReactNode, ReactText } from 'react'

import {
  bindMenu,
  bindTrigger,
  usePopupState
} from 'material-ui-popup-state/hooks'
import { FormattedMessage } from 'react-intl'

import {
  ListSubheader,
  Menu,
  OutlinedInput,
  TextField,
  TextFieldProps as MuiTextFieldProps
} from '@mui/material'

import ArrowDownGrayIcon from 'app/assets/icons/arrow-down-gray.svg'
import ArrowDownIcon from 'app/assets/icons/arrow-down.svg'
import CloseIcon from 'app/assets/icons/close-icon.svg'
import useModal from 'app/hooks/useModal'
import fonts from 'app/theme/common/fonts'
import { IS_SAFARI } from 'app/utils/constants/env.constants'
import { isOfType } from 'app/utils/helpers/type.helpers'

import Box from '../Box'
import Button, { ButtonProps } from '../Button/Button.web'
import IconButton from '../IconButton/IconButton.web'
import MenuItem from '../OptionMenuItem'
import Paper, { PaperProps } from '../Paper'
import Stack from '../Stack'
import Text from '../Text'

import { SelectBaseCommonProps } from './SelectBase.types'

type SelectVariantProps = Partial<
  Omit<MuiTextFieldProps, 'variant' | 'onChange'>
> &
  SelectBaseCommonProps & {
    variant: 'select'
    PaperSx?: PaperProps['sx']
    onOpen?: () => void
    showSelectedDescription?: boolean
    customLoadingText?: string
  }

type ButtonVariantProps = SelectBaseCommonProps &
  Omit<ButtonProps, 'children' | 'onPress' | 'onClick' | 'variant'> & {
    error?: boolean
    label?: ReactNode
    variant: 'button'
    helperText?: ReactText | ReactElement | ReactNode | null
    showSelectedDescription?: boolean
    customLoadingText?: string
  }

export type SelectBaseProps = SelectVariantProps | ButtonVariantProps

export const SelectVariant = ({
  SelectProps,
  multiple,
  value,
  inputValue,
  withFilter,
  options,
  onChange,
  onChangeInput,
  renderOptions,
  renderValue,
  placeholder,
  notResultText,
  helperText,
  withCreateOption,
  loading,
  variant,
  customLoadingText,
  PaperSx,
  sx,
  ...rest
}: SelectVariantProps) => {
  const { hideModal, open, showModal } = useModal()

  const menuItems = renderItemList({
    withFilter,
    items: options,
    onChangeInput,
    placeholder,
    renderItems: renderOptions,
    notResultText,
    withCreateOption,
    inputValue,
    loading,
    multiple,
    customLoadingText,
    onClose: () => {
      if (onChangeInput) {
        setTimeout(() => onChangeInput(''), 50)
      }
      hideModal()
    }
  })

  return (
    <TextField
      select
      helperText={helperText}
      size="small"
      value={value}
      sx={{
        '&>.MuiInputLabel-root': {
          paddingRight: 2,
          color: rest.disabled ? 'text.secondary' : 'text.primary'
        },
        '&>.MuiInputLabel-shrink': {
          color: 'text.secondary'
        },
        '& .MuiFormHelperText-root': {
          mx: 0,
          fontFamily: fonts.poppins.name,
          fontWeight: 300,
          fontSize: 12,
          lineHeight: '18px',
          color: 'text.secondary'
        },
        ...sx
      }}
      SelectProps={{
        ...SelectProps,
        multiple,
        displayEmpty: true,
        MenuProps: {
          autoFocus: false,
          PaperProps: {
            sx: {
              maxWidth: 350,
              maxHeight: 350,
              mt: 0.5,
              position: 'relative',

              '& .MuiMenu-list': {
                paddingBottom: 0
              },
              ...PaperSx
            }
          },
          className: 'scrollbar'
        },
        IconComponent: (props) => {
          const closeIcon = isOfType.array(value) ? !!value.length : !!value

          return (
            <Box
              {...props}
              sx={{
                width: 16,
                height: 16,
                mr: 0.5,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                pointerEvents: closeIcon ? 'auto !important' : 'none',
                transform: closeIcon ? 'none !important' : 'initial'
              }}
            >
              {closeIcon ? (
                <IconButton
                  size="small"
                  onClick={() => {
                    if (onChange) onChange('')
                  }}
                  disabled={rest.disabled}
                >
                  <CloseIcon viewBox="0 0 16 16" width={12} height={12} />
                </IconButton>
              ) : rest.disabled ? (
                <ArrowDownGrayIcon />
              ) : (
                <ArrowDownIcon />
              )}
            </Box>
          )
        },
        onChange: onChange,
        renderValue,
        open,
        onOpen: () => {
          showModal()

          if (rest?.onOpen) rest.onOpen()
        },
        onClose: () => {
          if (onChangeInput) {
            setTimeout(() => onChangeInput(''), 50)
          }
          hideModal()
        }
      }}
      InputLabelProps={{
        sx: {
          color: rest.disabled ? 'text.secondary' : 'text.primary'
        }
      }}
      inputProps={{
        'data-testid': 'select-input',
        ...rest.inputProps
      }}
      {...rest}
    >
      {menuItems}
    </TextField>
  )
}

export const ButtonVariant = ({
  value,
  withFilter,
  options,
  onChange,
  onChangeInput,
  placeholder,
  renderOptions,
  renderValue,
  notResultText,
  inputValue,
  multiple,
  label,
  withCreateOption,
  loading,
  variant,
  customLoadingText,
  ...rest
}: ButtonVariantProps) => {
  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'selectMenu'
  })

  const menuItems = renderItemList({
    withFilter,
    items: options,
    onChangeInput,
    placeholder,
    renderItems: renderOptions,
    notResultText,
    withCreateOption,
    inputValue,
    loading,
    multiple,
    customLoadingText
  })

  const isEmptyValue = isOfType.array(value) ? !value.length : !value

  return (
    <>
      <Button {...bindTrigger(popupState)} variant="text" {...rest}>
        <Stack
          spacing={1}
          direction="row"
          sx={{ justifyContent: 'center', alignItems: 'center' }}
        >
          <Text variant="body2" noWrap>
            {isEmptyValue ? label : renderValue(value)}
          </Text>
          <ArrowDownIcon />
        </Stack>
      </Button>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PaperProps={{
          sx: { maxHeight: 350 }
        }}
      >
        {React.Children.map(menuItems, (child) => {
          const currentValue = child.props?.value

          const selected = multiple
            ? value?.includes(currentValue)
            : currentValue === value

          return React.cloneElement(child, {
            ...child.props,
            ...(currentValue && {
              selected,
              onClick: () => {
                const currentValue = child.props?.value

                if (!currentValue) return

                if (multiple) {
                  const length = value?.length || 0
                  const newValues = value.filter(
                    (value) => value !== currentValue
                  )

                  if (newValues < length) {
                    onChange(newValues)
                  } else {
                    onChange([...newValues, currentValue])
                  }
                } else {
                  onChange(value === currentValue ? null : currentValue)
                }

                popupState.close()
              }
            })
          })
        })}
      </Menu>
    </>
  )
}

const renderItemList = ({
  withFilter,
  withCreateOption,
  onChangeInput,
  renderItems,
  items,
  placeholder,
  notResultText,
  inputValue,
  loading,
  multiple,
  customLoadingText,
  onClose = () => {}
}) => {
  const hasItems = !!items?.length
  const showPlaceholder = placeholder && hasItems

  return [
    withFilter && (
      <ListSubheader sx={{ pt: 1, mt: -1 }}>
        <OutlinedInput
          sx={{ height: '36px' }}
          size="small"
          placeholder="Search"
          fullWidth
          tabIndex={0}
          onChange={(e) => onChangeInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key !== 'Escape') {
              e.stopPropagation()
            }
          }}
        />
      </ListSubheader>
    ),
    ...(loading
      ? [
          <MenuItem disabled value="" key="loading">
            {customLoadingText ?? <FormattedMessage defaultMessage="Loading" />}{' '}
          </MenuItem>
        ]
      : [
          !placeholder && (
            <MenuItem variant="default" sx={{ display: 'none' }} value="">
              default
            </MenuItem>
          ),
          showPlaceholder && (
            <MenuItem variant={'default'} disabled value="">
              {placeholder}
            </MenuItem>
          ),

          hasItems && renderItems(items),
          multiple && hasItems && (
            <Paper
              key="done-button"
              sx={{
                p: 0,
                py: 1,
                px: 2,
                position: 'sticky',
                bottom: 0,
                zIndex: 100,
                borderRadius: 0,
                ...(IS_SAFARI && {
                  visibility: 'hidden',
                  '@keyframes fadeIn': {
                    '0%': { visibility: 'hidden' },
                    '100%': { visibility: 'visible' }
                  },
                  animation: 'fadeIn 0.3s forwards',
                  animationDelay: '0.3s'
                })
              }}
            >
              <Button size="medium" onClick={onClose}>
                <FormattedMessage defaultMessage="Done" />
              </Button>
            </Paper>
          ),
          !hasItems && !withCreateOption && (
            <MenuItem disabled>
              <Text variant="body2">
                {notResultText ? (
                  notResultText
                ) : (
                  <FormattedMessage defaultMessage="No results found" />
                )}
              </Text>
            </MenuItem>
          ),
          !hasItems && withCreateOption && inputValue && (
            <MenuItem value={inputValue}>
              <Text variant="body2">
                <FormattedMessage defaultMessage="Apply" /> {` "${inputValue}"`}
              </Text>
            </MenuItem>
          )
        ])
  ].filter(Boolean)
}

const variants = {
  select: SelectVariant,
  button: ButtonVariant
}

const SelectBase = ({ variant = 'select', ...rest }: SelectBaseProps) => {
  const Variant = variants[variant] as RFC<Omit<SelectBaseProps, 'variant'>>

  return <Variant {...rest} />
}

export default SelectBase
