export const inventoryKeys = {
  inventory: ['inventory'] as const,
  getUserInventories: (params) => [
    ...inventoryKeys.inventory,
    'getUserInventories',
    params
  ],
  updateQuantity: () => [...inventoryKeys.inventory, 'updateQuantity'],
  inventoryFileExport: () => [...inventoryKeys.inventory, 'inventoryFileExport']
}
