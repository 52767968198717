import NextImage, { ImageProps as NextImageProps } from 'next/image'
import React, { useMemo } from 'react'

import Base64 from 'app/utils/helpers/base64.helpers'
import imageLoader from 'app/utils/helpers/imageLoader'

export interface ImageProps extends NextImageProps {}

const Image = ({
  src,
  width = 64,
  height = 64,
  sizes,
  children,
  layout = 'responsive',
  objectFit = 'cover',
  objectPosition = 'center',
  alt,
  style,
  priority,
  loading,
  quality
}: ImageProps) => {
  const isSrcString = typeof src === 'string'
  const baseImage = useMemo(() => Base64.btoa(String(src)), [src])
  const image = isSrcString ? baseImage : src

  return (
    <>
      {src ? (
        <NextImage
          layout={layout}
          {...(isSrcString && { loader: imageLoader })}
          objectFit={objectFit}
          objectPosition={objectPosition}
          src={image}
          width={width}
          height={height}
          sizes={sizes}
          alt={alt}
          style={style}
          priority={priority}
          loading={loading}
          quality={quality}
        />
      ) : (
        children
      )}
    </>
  )
}

export default Image
