import React, { ReactNode, useCallback } from 'react'

import { ListSubheader } from '@mui/material'

import useAutoComplete from 'app/hooks/useAutocomplete'
import useOptions, { OptionModel, UseOptionsProps } from 'app/hooks/useOptions'
import { MAKE_SOURCE_TYPE } from 'app/utils/constants/listing.constants'
import { isOfType } from 'app/utils/helpers/type.helpers'

import MenuItem from '../OptionMenuItem'
import SelectBase, { SelectBaseProps } from '../SelectBase/SelectBase.web'
import Stack from '../Stack'
import Text from '../Text'

interface SelectCustomProps {
  value: any
  optionSettings?: Partial<UseOptionsProps>
  multiple?: boolean
  notResultText?: string
  sortOption?: boolean
  onChange: (value: any) => void
  groupBy?: (option) => string
  menuList?: () => void
  filterOptions?: (
    options: Option[],
    {
      inputValue
    }: {
      inputValue: string
    }
  ) => OptionModel[]
  helperText?: ReactNode
  divider?: boolean
  inputProps?: {
    IconComponent?: React.FC
  }
  isUniversalOption?: boolean
  showSelectedDescription?: boolean
  customRenderOption?: (option) => ReactNode
}

export type SelectProps = Partial<
  Omit<SelectBaseProps, 'options' | 'renderOptions'>
> &
  SelectCustomProps

const Select: RFC<SelectProps> = ({
  optionSettings,
  value,
  onChange,
  multiple: multipleProp,
  withFilter,
  filterOptions,
  groupBy,
  sortOption,
  isUniversalOption,
  showSelectedDescription,
  customRenderOption,
  ...rest
}) => {
  const multiple = multipleProp || optionSettings?.multiple
  const defaultValue = multiple ? [] : ''
  const itemType = multiple ? 'checkbox' : 'default'

  const { options, loading } = useOptions({
    key: 'selectOptions',
    ...optionSettings,
    multiple: multiple,
    value
  })

  const {
    inputValue,
    setInputValue,
    options: newOptions
  } = useAutoComplete({
    filterOptions,
    groupBy,
    options,
    sortOption
  })

  const renderOptions = useCallback(
    (options) => {
      return options?.map(
        (
          { firstInGroup, label, value, group, description, source },
          index: number
        ) => {
          const isSystem = source === MAKE_SOURCE_TYPE.system
          const isDisabled = isUniversalOption && !isSystem
          const isLastOption = index === options?.length - 1

          const menuItem = (
            <MenuItem
              key={value}
              value={value}
              variant={itemType}
              disabled={isDisabled}
              sx={{ width: '100%' }}
            >
              {!!customRenderOption &&
                customRenderOption({ ...options[index], isLastOption })}

              {!customRenderOption && (
                <Stack direction="column">
                  <Text
                    variant="body2"
                    sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                  >
                    {label}
                  </Text>

                  {description && (
                    <Text
                      variant="subtitle2"
                      sx={{
                        maxWidth: 480,
                        whiteSpace: 'pre-wrap',
                        color: 'text.third'
                      }}
                    >
                      {description}
                    </Text>
                  )}
                </Stack>
              )}
            </MenuItem>
          )

          if (firstInGroup) {
            return [
              <ListSubheader disableSticky key={group}>
                {group}
              </ListSubheader>,
              menuItem
            ]
          }

          return menuItem
        }
      )
    },
    [itemType, isUniversalOption]
  )

  const renderValue = (value) => {
    if (multiple && isOfType.array(value)) {
      const selectedOptions = options?.filter((o) => value.includes(o.value))

      return (
        <Text variant="body2" noWrap>
          {selectedOptions?.map((item) => item?.label).join(', ')}
        </Text>
      )
    }

    const selectedOption = options?.find((item) => item?.value === value)

    const label = selectedOption?.label || value

    if (showSelectedDescription) {
      return (
        <Stack direction="column">
          <Text variant="body2" noWrap>
            {label}
          </Text>

          {!!selectedOption?.description && (
            <Text
              variant="subtitle2"
              lineClamp={1}
              sx={{ color: 'text.third' }}
            >
              {selectedOption.description}
            </Text>
          )}
        </Stack>
      )
    }

    return (
      <Text variant="body2" noWrap>
        {label}
      </Text>
    )
  }

  return (
    <SelectBase
      value={value || defaultValue}
      inputValue={inputValue}
      onChange={onChange}
      multiple={multiple}
      onChangeInput={(value) => setInputValue(value)}
      withFilter={withFilter}
      options={newOptions}
      renderValue={renderValue}
      renderOptions={renderOptions}
      loading={loading}
      {...(rest as any)}
    />
  )
}

export default Select
