import {
  QueryKey,
  UseMutateAsyncFunction,
  useMutation,
  useQueryClient
} from 'react-query'

import {
  addListingToCart,
  bulkUpload,
  createLikedListing,
  createListing,
  deactivateUserListing,
  deleteDraftListing,
  deleteLikedListing,
  deleteListingFromCart,
  deleteUserListing,
  duplicateUserListing,
  editPublishedListing,
  generateListingLabel,
  getAwsConfigData,
  publishAllListings,
  publishListing,
  updateBlockedListing,
  updateCartListing,
  updateListing,
  uploadToAws,
  validateCartListings,
  updateTradePriceAllListings,
  deactivateTradePriceAllListings
} from 'app/services/Api/listing'
import { inventoryKeys } from 'app/services/Query/inventory/inventory.keys'
import { IS_NATIVE_APP } from 'app/utils/constants/env.constants'
import { hasStatusError } from 'app/utils/helpers/type.helpers'

import { userKeys } from '../user/user.keys'

import { listingKeys } from './listing.keys'

interface MutationConfig {
  createListing: {
    key: QueryKey
    request: UseMutateAsyncFunction<
      ListingModel,
      string,
      Partial<ListingDTO>,
      unknown
    >
  }
  updateListing: {
    key: QueryKey
    request: UseMutateAsyncFunction<
      ListingModel,
      string,
      { slug?: string; data: Partial<ListingDTO> },
      unknown
    >
  }
  deleteListing: {
    key: QueryKey
    request: UseMutateAsyncFunction<void, string, string, unknown>
  }
  deactivateListing: {
    key: QueryKey
    request: UseMutateAsyncFunction<void, string, string, unknown>
  }
  duplicateListing: {
    key: QueryKey
    request: UseMutateAsyncFunction<ListingModel, string, string, unknown>
  }
  deleteDraftListing: {
    key: QueryKey
    request: UseMutateAsyncFunction<void, string, string, unknown>
  }
  publishListing: {
    key: QueryKey
    request: UseMutateAsyncFunction<ListingModel, string, string, unknown>
  }
  publishAllListings: {
    key: QueryKey
    request: UseMutateAsyncFunction<ListingModel[], string, void, unknown>
  }
  editPublishedListing: {
    key: QueryKey
    request: UseMutateAsyncFunction<
      ListingModel,
      string,
      { slug?: string; data: Partial<ListingDTO> },
      unknown
    >
  }
  getAwsConfigData: {
    key: QueryKey
    request: UseMutateAsyncFunction<UploadConfigData, string, void, unknown>
  }
  uploadToAsw: {
    key: QueryKey
    request: UseMutateAsyncFunction<string, string, UploadToAswDTO>
  }
  bulkUpload: {
    key: QueryKey
    request: UseMutateAsyncFunction<string, string, BulkUploadDTO>
  }
  createLikedListing: {
    key: QueryKey
    request: UseMutateAsyncFunction<void, string, string, unknown>
  }
  deleteLikedListing: {
    key: QueryKey
    request: UseMutateAsyncFunction<void, string, string, unknown>
  }
  addListingToCart: {
    key: QueryKey
    request: UseMutateAsyncFunction<void, string, AddCartListingDTO>
  }
  updateCartListing: {
    key: QueryKey
    request: UseMutateAsyncFunction<void, string, UpdateCartListingDTO, unknown>
  }
  deleteListingFromCart: {
    key: QueryKey
    request: UseMutateAsyncFunction<void, string, number, unknown>
  }
  validateCartListings: {
    key: QueryKey
    request: UseMutateAsyncFunction<void, string, void, unknown>
  }
  generateListingLabel: {
    key: QueryKey
    request: UseMutateAsyncFunction<InventoryFileModel, string, string, unknown>
  }
  updateBlockedListing: {
    key: QueryKey
    request: UseMutateAsyncFunction<
      ListingModel,
      string,
      { slug?: string; data: Partial<ListingDTO> },
      unknown
    >
  }
  updateTradePriceAllListings: {
    key: QueryKey
    request: UseMutateAsyncFunction<void, string, TradePriceFV>
  }
  deactivateTradePriceAllListings: {
    key: QueryKey
    request: UseMutateAsyncFunction<void, string, void>
  }
}

const listingMutationConfig: MutationConfig = {
  createListing: {
    key: listingKeys.createListing(),
    request: createListing
  },
  updateListing: {
    key: listingKeys.updateListing(),
    request: updateListing
  },
  deleteListing: {
    key: listingKeys.deleteListing(),
    request: deleteUserListing
  },
  deactivateListing: {
    key: listingKeys.deactivateListing(),
    request: deactivateUserListing
  },
  duplicateListing: {
    key: listingKeys.duplicateListing(),
    request: duplicateUserListing
  },
  deleteDraftListing: {
    key: listingKeys.deleteDraftListing(),
    request: deleteDraftListing
  },
  publishListing: {
    key: listingKeys.publishListing(),
    request: publishListing
  },
  publishAllListings: {
    key: listingKeys.publishAllListings(),
    request: publishAllListings
  },
  editPublishedListing: {
    key: listingKeys.editPublishedListing(),
    request: editPublishedListing
  },
  getAwsConfigData: {
    key: listingKeys.getAwsConfigData(),
    request: getAwsConfigData
  },
  uploadToAsw: {
    key: listingKeys.uploadToAsw(),
    request: uploadToAws
  },
  bulkUpload: {
    key: listingKeys.bulkUpload(),
    request: bulkUpload
  },
  createLikedListing: {
    key: listingKeys.createLikedListing(),
    request: createLikedListing
  },
  deleteLikedListing: {
    key: listingKeys.deleteLikedListing(),
    request: deleteLikedListing
  },
  addListingToCart: {
    key: listingKeys.addListingToCart(),
    request: addListingToCart
  },
  updateCartListing: {
    key: listingKeys.updateCartListing(),
    request: updateCartListing
  },
  deleteListingFromCart: {
    key: listingKeys.deleteListingFromCart(),
    request: deleteListingFromCart
  },
  validateCartListings: {
    key: listingKeys.validateCartListings(),
    request: validateCartListings
  },
  generateListingLabel: {
    key: listingKeys.generateListingLabel(),
    request: generateListingLabel
  },
  updateBlockedListing: {
    key: listingKeys.updateBlockedListing(),
    request: updateBlockedListing
  },
  updateTradePriceAllListings: {
    key: listingKeys.updateTradePriceAllListings(),
    request: updateTradePriceAllListings
  },
  deactivateTradePriceAllListings: {
    key: listingKeys.deactivateTradePriceAllListings(),
    request: deactivateTradePriceAllListings
  }
}

export const useCreateListingMutation = () => {
  const { createListing: config } = listingMutationConfig
  const queryClient = useQueryClient()

  const state = useMutation(config.key, config.request, {
    onSuccess: () => {
      if (IS_NATIVE_APP) {
        queryClient.invalidateQueries(listingKeys.listing)
      }
    }
  })

  return state
}

export const useUpdateListingMutation = () => {
  const { updateListing: config } = listingMutationConfig
  const queryClient = useQueryClient()

  const state = useMutation(config.key, config.request, {
    onSuccess: () => {
      if (IS_NATIVE_APP) {
        queryClient.invalidateQueries(listingKeys.listing)
      }
    }
  })

  return state
}

export const useDeleteListingMutation = () => {
  const { deleteListing: config } = listingMutationConfig
  const queryClient = useQueryClient()

  const state = useMutation(config.key, config.request, {
    onSuccess: () => {
      queryClient.invalidateQueries(listingKeys.listing)
      queryClient.invalidateQueries(userKeys.user)
    }
  })

  return state
}

export const useDeactivateListingMutation = () => {
  const { deactivateListing: config } = listingMutationConfig
  const queryClient = useQueryClient()

  const state = useMutation(config.key, config.request, {
    onSuccess: () => {
      queryClient.invalidateQueries(listingKeys.listing)
      queryClient.invalidateQueries(userKeys.user)
    }
  })

  return state
}

export const useDuplicateUserListingMutation = () => {
  const { duplicateListing: config } = listingMutationConfig

  const state = useMutation(config.key, config.request)

  return state
}

export const useDeleteDraftListingMutation = () => {
  const { deleteDraftListing: config } = listingMutationConfig
  const queryClient = useQueryClient()

  const state = useMutation(config.key, config.request, {
    onSuccess: () => {
      queryClient.invalidateQueries(listingKeys.listing)
    }
  })

  return state
}

export const usePublishListingMutation = () => {
  const { publishListing: config } = listingMutationConfig
  const queryClient = useQueryClient()

  const state = useMutation(config.key, config.request, {
    onSuccess: () => {
      queryClient.invalidateQueries(listingKeys.listing)
      queryClient.invalidateQueries(inventoryKeys.inventory)
      queryClient.invalidateQueries(userKeys.user)
    }
  })

  return state
}

export const usePublishAllListingsMutation = () => {
  const { publishAllListings: config } = listingMutationConfig
  const queryClient = useQueryClient()

  const state = useMutation(config.key, config.request, {
    onSuccess: () => {
      queryClient.invalidateQueries(listingKeys.listing)
      queryClient.invalidateQueries(inventoryKeys.inventory)
    },
    onError: (error) => {
      if (hasStatusError(error) && error?.status === 406) {
        queryClient.invalidateQueries(listingKeys.listing)
      }
    }
  })

  return state
}

export const useEditPublishedListingMutation = () => {
  const { editPublishedListing: config } = listingMutationConfig
  const queryClient = useQueryClient()

  const state = useMutation(config.key, config.request, {
    onSuccess: () => {
      queryClient.invalidateQueries(listingKeys.listing)
    }
  })

  return state
}

export const useGetAwsConfigDataMutation = () => {
  const { getAwsConfigData: config } = listingMutationConfig

  const state = useMutation(config.key, config.request)

  return state
}

export const useUploadToAwsMutation = () => {
  const { uploadToAsw: config } = listingMutationConfig

  const state = useMutation(config.request)

  return state
}

export const useBulkUploadMutation = () => {
  const { bulkUpload: config } = listingMutationConfig

  const state = useMutation(config.request)

  return state
}

export const useCreateLikedListingMutation = () => {
  const queryClient = useQueryClient()

  const { createLikedListing: config } = listingMutationConfig

  const state = useMutation(config.key, config.request, {
    onSuccess: () => {
      queryClient.invalidateQueries(listingKeys.listing)
    }
  })

  return state
}

export const useDeleteLikedListingMutation = () => {
  const queryClient = useQueryClient()

  const { deleteLikedListing: config } = listingMutationConfig

  const state = useMutation(config.key, config.request, {
    onSuccess: () => {
      queryClient.invalidateQueries(listingKeys.listing)
    }
  })

  return state
}

export const useAddListingToCartMutation = () => {
  const queryClient = useQueryClient()

  const { addListingToCart: config } = listingMutationConfig

  const state = useMutation(config.key, config.request, {
    onSuccess: () => {
      queryClient.invalidateQueries(listingKeys.listing)
    }
  })

  return state
}

export const useChangeInCartListingMutation = () => {
  const queryClient = useQueryClient()
  const { updateCartListing: config } = listingMutationConfig

  const state = useMutation(config.key, config.request, {
    onSuccess: () => {
      queryClient.invalidateQueries(listingKeys.getCartTotalPrice())
    },
    onError: () => {
      queryClient.invalidateQueries(listingKeys.listing)
    }
  })

  return state
}

export const useDeleteListingFromCartMutation = () => {
  const queryClient = useQueryClient()

  const { deleteListingFromCart: config } = listingMutationConfig

  const state = useMutation(config.key, config.request, {
    onSettled: () => {
      queryClient.invalidateQueries(listingKeys.listing)
    }
  })

  return state
}

export const useValidateCartListingsMutation = () => {
  const queryClient = useQueryClient()

  const { validateCartListings: config } = listingMutationConfig

  const state = useMutation(config.key, config.request, {
    onError: () => {
      queryClient.invalidateQueries(listingKeys.listing)
    }
  })

  return state
}

export const useGenerateListingLabel = () => {
  const { generateListingLabel: config } = listingMutationConfig

  const state = useMutation(config.key, config.request)

  return state
}

export const useUpdateBlockedListing = () => {
  const { updateBlockedListing: config } = listingMutationConfig

  const state = useMutation(config.key, config.request)

  return state
}

export const useUpdateTradePriceAllListings = () => {
  const queryClient = useQueryClient()
  const { updateTradePriceAllListings: config } = listingMutationConfig

  const state = useMutation(config.key, config.request, {
    onSuccess: () => {
      queryClient.invalidateQueries(listingKeys.listing)
      queryClient.invalidateQueries(inventoryKeys.inventory)
    }
  })

  return state
}

export const useDeactivateTradePriceAllListings = () => {
  const queryClient = useQueryClient()
  const { deactivateTradePriceAllListings: config } = listingMutationConfig

  const state = useMutation(config.key, config.request, {
    onSuccess: () => {
      queryClient.invalidateQueries(listingKeys.listing)
      queryClient.invalidateQueries(inventoryKeys.inventory)
    }
  })

  return state
}
